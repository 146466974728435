import gsap from '../animations/gsap'
import { fadeInBottom, revealInRight } from '../animations/animation'
import { mq, mqMap } from '../utilities/breakpoints'

// Selector
const selector = '.section-register'
const q = gsap.utils.selector(selector)

// Timeline
const tl = gsap.timeline({
  scrollTrigger: {
    trigger: selector,
    start: 'top 60%',
  },
})

// Contents
tl.from(q('.fade-in-bottom'), fadeInBottom)

// Decorations
switch (mq()) {
  case 'default':
    tl.from(q('.aurora-mobile.reveal-in-right'), revealInRight, '>-.2')
    break
  case 'md':
    tl.from(q('.aurora-tablet.reveal-in-right'), revealInRight, '>-.2')
    break
  default:
    tl.from(q('.aurora-desktop.reveal-in-right'), revealInRight)
    break
}
