import { mq, mqMap } from '../utilities/breakpoints'
import gsap from '../animations/gsap'
import {
  fadeIn,
  fadeInBottom,
  parallax,
  revealInLeft,
  revealInRight,
} from '../animations/animation'

// In Out animation for title
const titleInOut = {
  ...fadeInBottom,
  stagger: false,
  yoyo: true,
  repeat: 1,
  repeatDelay: 1.5,
}

// Selector
const selector = '.section-xu-privileges'
const q = gsap.utils.selector(selector)

// Reset opacity
gsap.set(q('.title'), { opacity: 1 })

// Timeline
const tl = gsap.timeline({
  scrollTrigger: {
    trigger: selector,
    start: mqMap({ default: 'top 45%', lg: 'top 30%' }),
  },
})

// Card
const p = mqMap({
  default: parallax(selector, '+=40', true),
  md: parallax(selector, '+=60', true),
  lg: parallax(selector, '+=100', true),
})
tl.to(q('.parallax'), p)

// XU Privileges, Rewards, Benefits
tl.add(
  gsap
    .timeline({
      repeat: -1,
      repeatDelay: 0.5,
    })
    .from(q('.title-privileges'), titleInOut)
    .from(q('.title-rewards'), titleInOut, '>.5')
    .from(q('.title-benefits'), titleInOut, '>.5'),
)

// Detail text
tl.from(q('.fade-in-bottom'), fadeInBottom, '<.6')

// Decorations
switch (mq()) {
  case 'default':
    tl.from(q('.aurora-mobile.reveal-in-left'), revealInLeft)
    break
  case 'md':
    tl.from(q('.aurora-tablet.reveal-in-right'), revealInRight)
    tl.from(q('.aurora-tablet.reveal-in-left'), revealInLeft)
    break
  default:
    tl.from(q('.aurora-desktop.reveal-in-right'), revealInRight)
    tl.from(q('.aurora-desktop.reveal-in-left'), revealInLeft)
    break
}
tl.from(q('.fade-in'), fadeIn)
