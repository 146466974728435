import { mqMap } from '../utilities/breakpoints'
import gsap from '../animations/gsap'
import { fadeIn, fadeInBottom } from '../animations/animation'
import YouTubePlayer from 'youtube-player'

// Selector
const selector = '.section-xu-digital-credit-card'
const q = gsap.utils.selector(selector)

// Timeline
const tl = gsap.timeline({
  scrollTrigger: {
    trigger: selector,
    start: mqMap({ default: 'top 55%', lg: 'top 55%' }),
    onEnter: () => initYoutube(),
  },
})

// Cover left and right
const leftRightDuration = mqMap({ default: 1, md: 1.5, lg: 2.25 })
const leftRightTransform = mqMap({ default: -580, md: -300, lg: -600, xl: -600 })
tl.from(q('.cover-left'), {
  duration: leftRightDuration,
  left: leftRightTransform,
}).from(
  q('.cover-right'),
  {
    duration: leftRightDuration,
    right: leftRightTransform,
  },
  '<',
)

// Decorations
tl.from(q('.fade-in'), fadeIn, '>-.6')

// Contents
tl.from(q('.fade-in-bottom'), fadeInBottom)

// Youtube
const initYoutube = () => {
  const videoBlock = document.querySelector('.video-block')

  const player = YouTubePlayer('ytPlayer', {
    videoId: 'FKrVCQ7U9ic',
    playerVars: {
      playsinline: 0,
    },
  })

  videoBlock.addEventListener('click', () => {
    videoBlock.classList.add('active')
    player.playVideo()
  })
}
