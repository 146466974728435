import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import { mqMap } from '../utilities/breakpoints'

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin)

gsap.defaults({
  duration: 0.4,
  ease: 'power2.out',
})

// Smooth scroll to anchor
// Find offset
function findOffset(offsetString) {
  const offset = JSON.parse(offsetString)

  if (typeof offset === 'object') return mqMap(offset)

  return offset
}

// Detect if a link's href goes to the current page
function getSamePageAnchor(link) {
  if (
    link.protocol !== window.location.protocol ||
    link.host !== window.location.host ||
    link.pathname !== window.location.pathname ||
    link.search !== window.location.search
  ) {
    return false
  }

  return link.hash
}

// Scroll to a given hash, preventing the event given if there is one
function scrollToHash(hash, offset, duration = 1) {
  const elem = hash ? document.querySelector(hash) : false

  if (elem) {
    gsap.to(window, {
      duration: duration,
      scrollTo: {
        y: elem,
        offsetY: parseInt(offset) + 60,
      },
    })
  }
}

// If a link's href is within the current page, scroll to it instead
document.querySelectorAll('a[href]').forEach((a) => {
  a.addEventListener('click', (e) => {
    const hash = getSamePageAnchor(a)

    if (hash) {
      const offset = findOffset(document.querySelector(hash).dataset.scrollOffset ?? 0)

      e.preventDefault()
      scrollToHash(hash, offset)
    }
  })
})

// Scroll to the element in the URL's hash on load
document.addEventListener('DOMContentLoaded', function () {
  setTimeout(() => {
    const hash = window.location.hash

    if (hash) {
      const offset = findOffset(document.querySelector(hash).dataset.scrollOffset ?? 0)

      scrollToHash(hash, offset, 0)
    }
  }, 300)
})

export default gsap
