import Swiper, { Pagination, Autoplay } from 'swiper'
Swiper.use([Pagination, Autoplay])

const topBannerSwiper = new Swiper('.top-banner-swiper', {
  pagination: {
    el: '.top-banner-pagination',
    clickable: true,
  },
  autoplay: {
    delay: 5000,
    disableOnInteraction: false,
  },
  speed: 600,
  loop: true,
})
