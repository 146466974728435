import { fadeInBottom, parallax, revealInLeft, revealInRight } from '../animations/animation'
import gsap from '../animations/gsap'
import { mq, mqMap } from '../utilities/breakpoints'

// Selector
const selector = '.section-xu-point-redemption'
const q = gsap.utils.selector(selector)
const mediaQuery = mq()

// Timeline
const tl = gsap.timeline({
  scrollTrigger: {
    trigger: selector,
    start: mqMap({ default: 'top 45%', lg: 'top 30%' }),
  },
})

// Contents
tl.from(q('.fade-in-bottom'), fadeInBottom)

// Decorations
switch (mediaQuery) {
  case 'default':
    tl.from(q('.aurora-mobile.reveal-in-right'), revealInRight, 0)
    tl.from(q('.aurora-mobile.reveal-in-left'), revealInLeft, '>')
    break
  case 'md':
    tl.from(q('.aurora-tablet.reveal-in-right'), revealInRight, 0)
    tl.from(q('.aurora-tablet.reveal-in-left'), revealInLeft, '>')
    break
  default:
    tl.from(q('.aurora-desktop.reveal-in-right'), revealInRight, 0)
    tl.from(q('.aurora-desktop.reveal-in-left'), revealInLeft, '>')
    break
}

// Parallax
const pCard = mqMap({
  default: parallax(selector, 40, true),
  lg: parallax(selector, 80, true),
})
const pCoin = mqMap({
  default: parallax(selector, 20, true),
  lg: parallax(selector, 40, true),
})
gsap.to(q('.parallax-card'), pCard)
gsap.to(q('.parallax-coin'), pCoin)
