import Swiper, { Navigation } from 'swiper'
import { fadeInBottom, revealInLeft, revealInRight } from '../animations/animation'
import gsap from '../animations/gsap'
import { mq, mqMap } from '../utilities/breakpoints'

Swiper.use([Navigation])

// Animations
const animate = () => {
  // Selector
  const selector = '.section-xusive-benefits'
  const q = gsap.utils.selector(selector)

  // Timeline
  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: selector,
      start: mqMap({ default: 'top 45%', lg: 'top 50%' }),
    },
  })

  // Contents
  tl.from(q('.fade-in-bottom'), fadeInBottom)
  tl.from(q('.fade-in-bottom-fast'), {
    ...fadeInBottom,
    stagger: 0.35,
  })

  // Decorations
  switch (mq()) {
    case 'default':
      tl.from(q('.aurora-mobile.reveal-in-right'), revealInRight, 0)
      tl.from(q('.aurora-mobile.reveal-in-left'), revealInLeft, '>')
      break
    case 'md':
      tl.from(q('.aurora-tablet.reveal-in-right'), revealInRight, 0)
      tl.from(q('.aurora-tablet.reveal-in-left'), revealInLeft, '>')
      break
    default:
      tl.from(q('.aurora-desktop.reveal-in-right'), revealInRight, 0)
      tl.from(q('.aurora-desktop.reveal-in-left'), revealInLeft, '>')
      break
  }
}

// Swiper
const xusiveBenefitsSwiper = new Swiper('.xusive-benefits-swiper', {
  speed: 600,
  navigation: {
    nextEl: '.xusive-benefits-swiper-navigation-block .swiper-button-next',
    prevEl: '.xusive-benefits-swiper-navigation-block .swiper-button-prev',
  },
  slidesPerView: 1,
  spaceBetween: 30,
  breakpoints: {
    768: {
      slidesPerView: 2,
    },
  },
  on: {
    init: animate,
  },
})
