import gsap from '../animations/gsap'
import { fadeInBottom } from '../animations/animation'

// Selector
const selector = '.section-uchoose'
const q = gsap.utils.selector(selector)

// Timeline
const tl = gsap.timeline({
  scrollTrigger: {
    trigger: selector,
    start: 'top 70%',
  },
})

// Contents
tl.from(q('.fade-in-bottom-no-stagger'), {
  ...fadeInBottom,
  stagger: 0,
})
tl.from(q('.fade-in-bottom'), fadeInBottom)
