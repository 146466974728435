import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

// Scroll trigger menu
const header = document.querySelector('.header')
ScrollTrigger.create({
  trigger: 'body',
  start: 'top top',
  toggleClass: { targets: header, className: 'scrolled' },
})

// Toggle desktop search
const toggleDesktopSearch = () => {
  desktopSearchIconBlock.classList.toggle('active')
  header.classList.toggle('drawer-active')
}

// Toggle mobile menu
const toggleMobileMenu = () => {
  mobileMenuIcon.classList.toggle('active')
  header.classList.toggle('drawer-active')
}

// Desktop search icon
const desktopSearchIconBlock = document.querySelector('.desktop-search-icon-block')
desktopSearchIconBlock.addEventListener('click', toggleDesktopSearch)

// Backdrop closes search
const backdrop = document.querySelector('.backdrop')
backdrop.addEventListener('click', toggleDesktopSearch)

// Mobile menu icon
const mobileMenuIcon = document.querySelector('.menu-icon-block')
mobileMenuIcon.addEventListener('click', toggleMobileMenu)
