import Swiper, { Autoplay } from 'swiper'
import { fadeInBottom, revealInLeft, revealInRight } from '../animations/animation'
import gsap from '../animations/gsap'
import { mq, mqMap } from '../utilities/breakpoints'

Swiper.use([Autoplay])

// Animations
const animate = (swiper) => {
  // Selector
  const selector = '.section-xu-promotion'
  const q = gsap.utils.selector(selector)

  // Timeline
  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: selector,
      start: mqMap({ default: 'top 45%', lg: 'top 30%' }),
    },
    onComplete: () => swiper.autoplay.start(),
  })

  // Contents
  tl.from(q('.fade-in-bottom'), fadeInBottom)

  // swiper
  const selectorSwiperSlide = '.fade-in-bottom-fast:not(.swiper-slide-duplicate)'
  const selectorSwiperSlideMQ = mqMap({
    default: q(selectorSwiperSlide),
    md: q('.fade-in-bottom-fast.swiper-slide-prev, ' + selectorSwiperSlide),
    lg: q(selectorSwiperSlide),
  })
  tl.from(selectorSwiperSlideMQ, {
    ...fadeInBottom,
    stagger: 0.35,
  })

  // Decorations
  switch (mq()) {
    case 'default':
      tl.from(q('.aurora-mobile.reveal-in-right'), revealInRight, 0)
      tl.from(q('.aurora-mobile.reveal-in-left'), revealInLeft, '>')
      break
    case 'md':
      tl.from(q('.aurora-tablet.reveal-in-right'), revealInRight, 0)
      tl.from(q('.aurora-tablet.reveal-in-left'), revealInLeft, '>')
      break
    default:
      tl.from(q('.aurora-desktop.reveal-in-right'), revealInRight, 0)
      tl.from(q('.aurora-desktop.reveal-in-left'), revealInLeft, '>')
      break
  }
}

const xusivePromotionSwiper = new Swiper('.xu-promotion-swiper', {
  speed: 600,
  slidesPerView: 'auto',
  spaceBetween: 30,
  autoplay: {
    delay: 5000,
    disableOnInteraction: false,
  },
  loop: true,
  loopAdditionalSlides: 3,
  breakpoints: {
    768: {
      slidesPerView: 2,
    },
    992: {
      slidesPerView: 3,
    },
  },
  on: {
    init: (swiper) => {
      swiper.autoplay.stop()
      animate(swiper)
    },
  },
})
