import { mqMap } from '../utilities/breakpoints'
import gsap from '../animations/gsap'
import { fadeIn, fadeInBottom, parallax } from '../animations/animation'

// Selector
const selector = '.section-xu-digital-world'
const q = gsap.utils.selector(selector)

// Reset opacity
gsap.set(q('.big-x, .big-u, .fade-in-bottom, .fade-in'), {
  opacity: 1,
})

// Timeline
const tl = gsap.timeline({
  scrollTrigger: {
    trigger: selector,
    start: mqMap({ default: 'top 40%', md: 'top 50%', lg: 'top 25%' }),
  },
})

// Contents
tl.from(q('.fade-in-bottom'), fadeInBottom)

// Decorations
tl.from(q('.fade-in'), fadeIn)

// Parallax XU
const p = mqMap({
  default: parallax(selector, 90),
  lg: parallax(selector, 150),
})
gsap.to(q('.parallax'), p)
