const screenSizes = ['default', 'md', 'lg', 'xl']

const breakpoints = {
  default: null,
  md: window.matchMedia('(min-width: 768px)'),
  lg: window.matchMedia('(min-width: 992px)'),
  xl: window.matchMedia('(min-width: 1200px)'),
}

export default breakpoints

export const mq = () => {
  let currentSize = null

  for (let [bp, mq] of Object.entries(breakpoints)) {
    if (!mq || mq.matches) currentSize = bp
  }

  return currentSize
}

export const mqMap = (queryObject) => {
  let size = mq()
  let match = queryObject[size]

  if (match) return match

  // Fallback to smaller screen size
  let screenSizeIndex = screenSizes.indexOf(size)
  while (!match && screenSizeIndex > 0) {
    screenSizeIndex -= 1
    const newSize = screenSizes[screenSizeIndex]
    match = queryObject[newSize]
  }

  return match
}
