import gsap from './gsap'
import { mqMap } from '../utilities/breakpoints'

export const fadeInBottom = {
  y: mqMap({ default: 40, lg: 60 }),
  opacity: 0,
  stagger: 0.4,
}

export const fadeIn = {
  opacity: 0,
}

export const revealInLeft = {
  clipPath: 'inset(0 100% 0 0)',
  stagger: 0.4,
}

export const revealInRight = {
  clipPath: 'inset(0 0 0 100%)',
  stagger: 0.4,
}

export const parallax = (selector, amount, reverse = false) => {
  return {
    y: (index) => {
      if (!reverse) return index % 2 ? amount : -1 * amount
      else return index % 2 ? -1 * amount : amount
    },
    scrollTrigger: {
      trigger: selector,
      scrub: mqMap({ default: 0.75, xl: 1.5 }),
    },
  }
}
